import * as React from 'react';
import styles from '../agtech.module.css';

import Layout from '../components/layout';
import Bullets from 'components/bullets';
import Tile from 'components/tile';
import GetConsultation from 'components/free-consultation';
import Blog from 'components/blog/widget';
import Booking from 'components/feedback';
import loadable from '@loadable/component';
import { KoroBox, Appetini, BeyondGreen } from 'components/carousel';
import identificators from 'components/googleAnalyticsIdentificators';

import { meta } from '../../../metaData';
import FAQ from 'components/faq';
import { useFaqData } from 'hooks/useFaqData';

const Routes = require('../../routes').Routes;

const LIST = [
  {
    title: 'Blockchain-Fueled system',
    text: 'Leverage the power of Blockchain to deliver excellent supply chain transparency and provide clear info on where your food is coming from, thus winning your consumers’ trust.',
  },
  {
    title: 'Custom-Designed Foodtech app',
    text: 'Turn your disruptive idea into a sustainable and results-driven solution to acquire next-gen efficiencies, gain a competitive edge over your rivals and delight end-users with rich functionality.',
  },
  {
    title: 'Mobile and Web Apps for Restaurants',
    text: 'Enhance customer experience by providing your end-users with a flexible online menu and table booking features. Integrate your solution with POS systems and local payment services to streamline your workflow.',
  },
  {
    title: 'Nutrition Tracking Apps',
    text: 'Help your end-users eat healthy with a food consumption tracking platform. Implement your project with wearable devices to enable the monitoring of received and burned calories.',
  },
  {
    title: 'Mobile Apps for Delivery Service',
    text: 'Turbo-boost your profits with a cross-platform Foodtech app. Enhance user experience and draw new clients by letting them check your restaurant menu, place online orders and make online payments.',
  },
  {
    title: 'Online Marketplace for Supermarkets',
    text: 'Captivate the audiences with a digital platform that allows for providing better service and expanding product choice, while increasing your own profits without any inventory constraints.',
  },
];

const SERVICES = [
  {
    name: 'agritech-startup',
    title: 'FoodTech Startup',
    text: 'Have a revolutionary idea? We are eager to create a project from scratch or perfect your existing MVP.',
    to: Routes.SERVICES_MVP_DEVELOPMENT,
  },
  {
    name: 'agritech-b2b-company',
    title: 'FoodTech B2B company',
    text: 'We gather a dedicated team to take care of every development step and while saving budget.',
    to: Routes.SERVICES_DEDICATED_TEAM,
  },
  {
    name: 'farm-owner',
    title: 'Restaurants and Food Retail',
    text: 'We embrace the latest tech trends to create new or reshape your legacy infrastructure with digitally-enabled solutions.',
    to: Routes.SERVICES_DIGITAL_TRANSFORMATION,
  },
];

interface Props {
  pathContext: {
    prePost: ReadonlyArray<any>;
  };
  location: Location;
}

function FoodTech({ pathContext: { prePost }, location }: Props) {
  const faqData = useFaqData('allStrapiIndustry', 'Foodtech');
  const SwiperSlider = loadable(() => import('components/swiper-slider'));

  return (
    <Layout
      title="Foodtech Software Development"
      subtitle="Powered with a deep understanding of the Foodtech sector, we leverage cutting-edge technology to help you design, build and monetize your solutions."
      linkText="Estimate your project"
      metaData={{ main: meta.foodTech, faq: faqData?.list }}
      pathname={location.pathname}
      linkId={identificators.ESTIMATE_YOUR_PROJECT_HEADER}
    >
      <section className="section">
        <div className="inner">
          <h2 className="title">FoodTech products we can build</h2>
          <p className="subtitle">Develop a Foodtech platform to surpass consumers expectations</p>
          <Bullets list={LIST} className={styles.bullets} />
        </div>
      </section>
      <SwiperSlider>
        <KoroBox />
        <Appetini />
        <BeyondGreen />
      </SwiperSlider>
      <section className="section">
        <div className="inner">
          <h2 className="title">Services we provide</h2>
          <p className="subtitle">Leave the development process to us</p>
          <Tile list={SERVICES} className={styles.imageBullets} />
        </div>
      </section>
      <GetConsultation
        title="Do you want to break new grounds?"
        text="Developing Foodtech services tailored to your business goals"
        linkText="Get free consultation"
        linkId={identificators.GET_A_FREE_CONSULTATION_BANNER}
      />
      <section className="section">
        <div className="inner">
          <FAQ data={faqData} />
          <Blog posts={prePost} />
        </div>
      </section>
      <Booking />
    </Layout>
  );
}

export default FoodTech;
